.NodeMenu,
.GlobalFlowMenu {
  padding: 1rem;
  margin-bottom: 1rem;
  box-shadow: 0px 3px 6px grey;
}
.NodeMenu ul {
  list-style: none;
  padding-left: 0px;
}
.node-section-title {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}
.GlobalFlowMenu {
  font-size: 0.8rem;
}
.edit-global,
.delete-global {
  cursor: pointer;
  font-size: 1rem;
}
.diagram-canvas {
  box-shadow: 0px 2px 4px grey;
  height: 580px;
  background-size: 20px 20px;
  background-image: linear-gradient(
      to right,
      rgba(54, 169, 231, 0.1) 1px,
      transparent 1px
    ),
    linear-gradient(to bottom, rgba(54, 169, 231, 0.1) 1px, transparent 1px);
}

.NodeMenuItem {
  background-color: white;
  margin-bottom: 4px;
  box-shadow: 0px 2px 4px gray;
}
.NodeMenuItem[draggable] {
  cursor: pointer;
}
.NodeMenuItem[draggable]::before {
  content: "+";
  padding-right: 10px;
  padding-left: 5px;
  color: black;
}
.NodeMenuItem.invalid::before {
  content: "\26a0";
  padding-right: 10px;
  padding-left: 5px;
  color: red;
}

.logic_height {
  height: 450;
  background: #fff;
}

.node-submenu {
  max-height: 410px;
  overflow-y: auto;
}

.variables-submenu {
  max-height: 250px !important;
}

.node-submenu .table-sm td,
.node-submenu .table-sm th {
  padding: 0.5rem !important;
}

.node-submenu::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.node-submenu::-webkit-scrollbar-track {
  background-color: #3e5367;
  border-radius: 100px;
}

.node-submenu::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background-color: #1abb9c;
}

.vpFormSubmitSpinner .spinner-border {
  height: 1rem !important;
  width: 1rem !important;
}

.workspace .table > :not(caption) > * > * {
  padding: 0.5rem !important;
}

.GlobalFlowMenu .dropdown-item {
  padding: 10px !important;
}

.hidden-button{
  display: none !important;
}

.highlight-success{
  color: blue;
}

.highlight-error{
  color: red;
}

.float-right{
  float: right !important;
}

.node-config-save-button{
  position: absolute !important;
  top: 0px;
  right: 60px;
}

.node-config-close-button{
  position: absolute !important;
  top: 0px;
  right: 0px;
  background: transparent;
  border: none;
}