// all common imports;

@mixin SubTable(
  $table-background,
  $table-td-group-background,
  $table-content-color,
  $dataset-table-border,
  $cursor-pointer-hover-background,
  $no-data-found-color
) {

  #root{
    $formBackgroundColor : $table-background
  }

  .main-content {
    background-color: var(--formBackgroundColor) !important;
    /*border-radius: 8px;*/
    box-shadow: 1px 0 1px rgba(0,0,0,0.1);
    padding: 14px !important;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    //box-shadow: $table-td-group-background 0px 7px 10px 0px;
    color: $table-content-color;
  }

  .table-heading {
    color: $table-content-color;
  }

  .arrowDownContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .arrow-icon {
    font-size: 14px;
    color: $table-content-color;
    font-weight: 600;
    transition: transform 0.5s;
  }

  .arrow-icon-up {
    transform: rotate(-180deg);
  }

  .arrow-icon-down {
    transform: rotate(0);
  }

  .borderBottom {
    border-bottom: 1px solid $dataset-table-border;
    margin-bottom: 10px;
  }

  .borderTop {
    border-top: 1px solid $dataset-table-border;
  }

  .cursor-not-allowed {
    cursor: not-allowed;
  }

  .table > thead > tr > th > input {
    cursor: pointer;
  }

  .header-icon-button {
    border: none !important;
    margin: 0 !important;
    font-size: 13px !important;
  }

  .icon-hover-effect {
    background-color: transparent;
    padding: 5px;
    border-radius: 4px;
  }

  .icon-hover-effect:hover {
    background-color: $cursor-pointer-hover-background;
  }

  .transition-header-icon > a {
    color: $table-content-color !important;
  }

  .zeroData {
    color: $no-data-found-color;
    padding: 16px;
  }

  .tableSpinner {
    width: 100%;
    text-align: center;
    color: $table-content-color;
    padding: 16px;
    position: absolute;
    bottom: 0;
    top: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .view-content-data{
    border-bottom: 1px dotted $dataset-table-border;
  }

  .view-content-label{
    font-size: 12px !important;
    font-weight: bold !important;
  }

  .edit-page-update-button{
    position: absolute;
    right:60px;
  }

}

html.default {
  @import "/src/styles/themes/theme-default.scss";
  @import "/src/styles/theme-color-variables.scss";
  @include SubTable(
    $table-background,
    $table-td-group-background,
    $table-content-color,
    $dataset-table-border,
    $cursor-pointer-hover-background,
    $no-data-found-color
  );
}
html.dark {
  @import "/src/styles/themes/theme-dark.scss";
  @import "/src/styles/theme-color-variables.scss";
  @include SubTable(
    $table-background,
    $table-td-group-background,
    $table-content-color,
    $dataset-table-border,
    $cursor-pointer-hover-background,
    $no-data-found-color
  );
}
