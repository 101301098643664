@mixin AppDashboard($app-box-hover-shadow-color, $white) {
    .folder-card {
        height: 100%;
        text-align: center;
        background: $white;
        padding: 15px 10px 5px 10px;
        border-radius:20px;
        transition: box-shadow 0.3s;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
      }
      .folder-card:hover {
        box-shadow: 0 0 11px $app-box-hover-shadow-color;
        background: white;
      }
  }
  
  html.default {
    @import "../../styles/themes/theme-default.scss";
    @import "../../styles/theme-color-variables.scss";
    @include AppDashboard($app-box-hover-shadow-color, $white);
  }
  html.dark {
    @import "../../styles/themes/theme-dark.scss";
    @import "../../styles/theme-color-variables.scss";
    @include AppDashboard($app-box-hover-shadow-color, $white);
  }
  