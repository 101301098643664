.card-group-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.cards-slider {
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 15px;
  padding: 10px 0;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
  }
}

.card-item {
  flex: 0 0 250px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 15px;
  transition: box-shadow 0.3s ease; 

  &:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); 
  }

  &.selected {
    border: 2px solid #007bff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); 
    background: #f8f9fa; 
  }
}

.card-content {
  display: flex;
  align-items: center;
  gap: 10px;
}

.card-icon {
  font-size: 24px;
}

.card-info {
  text-align: left;
}

.card-value {
  font-size: 24px;
  font-weight: bold;
}

.card-label {
  font-size: 14px;
  color: #666;
  margin: 5px 0 0;
}

.nav-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;

  &.left-arrow {
    left: 0;
  }

  &.right-arrow {
    right: 0;
  }
}

.reload-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
}

.no-data {
  width: 100%;
  text-align: center;
  padding: 20px;
  color: #888;
}