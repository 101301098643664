// Import your styles at the top level
@import "../../../styles/themes/theme-default.scss";
@import "../../../styles/themes/theme-dark.scss";
@import "../../../styles/theme-color-variables.scss";

// Define your mixin
@mixin ViewPageComponent($text-color) {

    #root{
        $formHeaderColor : $text-color;
    }

    .content {
        color: var(--formHeaderColor);
        font-size: 16px;
        font-weight: 600;
    }

    .border {
        border: 2px solid $text-color;
        border-radius: 10px;
    }

    .number-input input {
        padding-left: 45px; 
        padding-right: 45px;
    }

    .prefix {
        background-color: #ccc;
        color: black;
        display: flex;
        height: 38px !important;
        width: 35px !important;
        justify-content: center;
        align-items: center !important;
        margin: 21px 0px;
        position: absolute;
        margin-left: 10px !important;
        border-top-left-radius: 5px !important;
        border-bottom-left-radius: 5px !important;
        font-size: 16px !important;
    }

    .suffix {
        background-color: #ccc;
        color: black;
        margin: 21px 0px;
        margin-left: -45px !important;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        height: 38px !important;
        width: 35px !important;
        display: flex;
        justify-content: center;
        align-items: center !important;
        font-size: 16px !important;
    }

    .quill.readonly {
        pointer-events: none;
        opacity: 0.5;
    }

    .quill-popout-editor-button{
        margin-top: -92px;
        float: right;
        font-size: 12px;
        opacity: 0.76;
    }
  
    .input-group.number-input{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        width: max-content;
    }

    .ql-transcript {
        display: inline-block;
        padding: 0 5px;
        cursor: pointer;
        color: $text-color;
        font-size: 14px;
    
        &::before {
            content: "Transcript";
        }
    
        &.ql-active {
            color: #007bff;
        }
    }
}

// Use the mixin for different themes
html.default {
    @include ViewPageComponent($gray);
}

html.dark {
    @include ViewPageComponent($gray);
}
