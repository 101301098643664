// all common imports;

@mixin FilterForm($filter-form-heading-color,$webkit-scrollbar-background-light,$webkit-scrollbar-background-dark, $font-color-white) {
  .filterFormContainer {
    position: relative;
    display: flex !important;
    justify-content: start !important;
    height: 80vh;
  }
  
  .filterForm > .rjsf > .field > fieldset#root {
    display: flex;
    flex-wrap: wrap;
    justify-content: start !important;
    width: 100vw !important;
    margin-left: 10px;
  }
  
  .filterForm > .rjsf > .field > fieldset > .field {
    padding: 0 !important;
    width: 21.5% !important;
  }

  .filterForm .rjsf .form-group {
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }
  
  /*start: to show and hide the filter panel*/
  .filter-panel {
    width: 240px;
    height: 100%;
    transition: width 0.3s ease;
    position: relative;
    float: left;
  }
  
  .filter-panel.collapsed {
    width: 0%; /* Resize to 10% width */
    .main-content{
      display: None;
    }
  }
  
  .toggle-button {
    position: absolute;
    top: 40%;
    right: 3px;
    transform: translateY(-50%);
    background-color: $webkit-scrollbar-background-dark;
    color: $font-color-white;
    border: none;
    padding: 16px 4px 20px 10px;
    border-radius: 5px 0 0 5px;
    cursor: pointer;
    transition: right 0.3s ease;
  }
  
  .leftFilterForm {
    transition: margin-left 0.3s ease;
    margin-left: 240px; /* Same as .filter-panel width */
    width: calc(100% - 240px);
  }

  .leftFilterForm-full-width {
    transition: margin-left 0.3s ease;
    margin-left: 0px !important; /* Same as .filter-panel width */
    width: calc(100% - 0px) !important;
  }

  .filter-panel.collapsed + .leftFilterForm {
    margin-left: 0%;
  }
  
  /*end: to show and hide the filter panel*/

  .filter-heading {
    color: $filter-form-heading-color;
  }
  
  .verticalFilterForm .form-group.field.field-string,
  .verticalFilterForm .form-group.field.field-object,
  .verticalFilterForm .form-group.field.field-number,
  .verticalFilterForm .form-group.field.field-array {
    padding: 0 !important;
    width: 100% !important;
  }
  
  .verticalFilterForm {
    height: 60vh !important;
    overflow-y: auto;
  }
  
  .filterFormHeight {
    height: 80vh !important;
  }
  
  .verticalFilterForm::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    transition: 0.3s;
  }
  
  .verticalFilterForm::-webkit-scrollbar-track {
    background-color: transparent;
    transition: 0.3s;
  }
  
  .verticalFilterForm::-webkit-scrollbar-thumb {
    background-color: transparent;
    transition: 0.3s;
  }
  
  .verticalFilterForm:hover::-webkit-scrollbar {
    background-color: $webkit-scrollbar-background-dark;
  }
  
  .verticalFilterForm:hover::-webkit-scrollbar-track {
    background-color: $webkit-scrollbar-background-light;
  }
  
  .verticalFilterForm:hover::-webkit-scrollbar-thumb {
    background-color: $webkit-scrollbar-background-dark;
  }
  
  .rightFilterForm::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    transition: 0.3s;
  }
  
  .rightFilterForm::-webkit-scrollbar-track {
    background-color: transparent;
    transition: 0.3s;
  }
  
  .rightFilterForm::-webkit-scrollbar-thumb {
    background-color: transparent;
    transition: 0.3s;
  }
  
  .rightFilterForm:hover::-webkit-scrollbar {
    background-color: $webkit-scrollbar-background-dark;
  }
  
  .rightFilterForm:hover::-webkit-scrollbar-track {
    background-color: $webkit-scrollbar-background-light;
  }
  
  .rightFilterForm:hover::-webkit-scrollbar-thumb {
    background-color: $webkit-scrollbar-background-dark;
  }
  
  .leftFilterForm::-webkit-scrollbar {
    height: 6px;
    width: 6px;
    transition: 0.3s;
  }
  
  .leftFilterForm::-webkit-scrollbar-track {
    background-color: transparent;
    transition: 0.3s;
  }
  
  .leftFilterForm::-webkit-scrollbar-thumb {
    background-color: transparent;
    transition: 0.3s;
  }
  
  .leftFilterForm:hover::-webkit-scrollbar {
    background-color: $webkit-scrollbar-background-dark;
  }
  
  .leftFilterForm:hover::-webkit-scrollbar-track {
    background-color: $webkit-scrollbar-background-light;
  }
  
  .leftFilterForm:hover::-webkit-scrollbar-thumb {
    background-color: $webkit-scrollbar-background-dark;
  }
  
  .rightFilterForm {
    order: -1;
  }
  
  .overflowYAuto {
    overflow-y: auto;
  }
  
  @media screen and (min-width: 768px) and (max-width: 1024px) {
    .filterForm > .rjsf > .field > fieldset > .field {
      padding: 0 !important;
      width: 20% !important;
    }
  }
  
  @media screen and (max-width: 768px) {
    .filterFormContainer {
      position: relative;
      flex-direction: column;
      padding: 0 !important;
      height: auto;
    }
  
    .verticalFilterForm {
      height: auto !important;
      overflow: hidden !important;
    }
  
    .filterFormHeight {
      height: auto !important;
    }
  
    .filterForm > .rjsf > .field > fieldset > .field {
      width: 45% !important;
      margin: 0 5px;
    }
  
    .filterForm > .rjsf > .field > fieldset#root {
      width: 100% !important;
    }
  
    .rightFilterForm {
      order: 1;
      overflow-y: auto;
    }
  
    .verticalFilterForm > .rjsf > .field > fieldset > .field {
      width: 44% !important;
      padding: 0 !important;
      margin: 0 !important;
    }
  
    .verticalFilterForm > .rjsf > .field > fieldset#root {
      width: 100% !important;
    }
  }
  
  @media screen and (max-width: 400px) {
    .verticalFilterForm > .rjsf > .field > fieldset > .field {
      width: 100% !important;
      padding: 0 !important;
    }
  
    .filterForm > .rjsf > .field > fieldset#root {
      width: 93% !important;
    }
  
    .filterForm > .rjsf > .field > fieldset > .field {
      width: 100% !important;
      margin: 0 5px;
    }
  }
  
}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include FilterForm($filter-form-heading-color,$webkit-scrollbar-background-light,$webkit-scrollbar-background-dark, $font-color-white);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include FilterForm($filter-form-heading-color,$webkit-scrollbar-background-light,$webkit-scrollbar-background-dark, $font-color-white);
}