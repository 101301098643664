// all common imports;

@mixin Kanban($kanban-heading-color,	$webkit-scrollbar-background-dark,	$webkit-scrollbar-background-light,	$kanban-background,	$kanban-hover-background,	$kanban-card-label-border,	$kanban-card-action-dropdown-color,	$kanban-card-preview-background, $border-color-light, $border-color-dark) {
  .kanban-board {
    font-size:12px !important;
    border-radius: 4px;
    overflow-x: auto;
  }
  
  .kanban-board-heading {
    font-size:16px !important;
    color: $kanban-heading-color;
  }
  
  .kanban-board::-webkit-scrollbar {
    height: 6px;
    transition: 0.3s;
  }
  
  .kanban-board::-webkit-scrollbar-track {
    background-color: transparent;
    transition: 0.3s;
  }
  
  .kanban-board::-webkit-scrollbar-thumb {
    background-color: transparent;
    transition: 0.3s;
  }
  
  .kanban-board:hover::-webkit-scrollbar {
    background-color: $webkit-scrollbar-background-dark;
  }
  
  .kanban-board:hover::-webkit-scrollbar-track {
    background-color: $webkit-scrollbar-background-light;
  }
  
  .kanban-board:hover::-webkit-scrollbar-thumb {
    background-color: $webkit-scrollbar-background-dark;
  }
  
  .kanban-board-container {
    font-size:12px !important;
    min-height: 50px;
    overflow: auto;
  }
  
  .kanban-lane-header {
    font-size: 14px;
  }
  
  .kanban-card {
    background-color: $kanban-background;
    min-height: 60px;
    border: none;
  }
  
  .kanban-card:hover {
    border:1px solid $border-color-dark;
  }
  
  .kanban-card-title {
    font-size: 12px;
    word-wrap: break-word;
  }
  
  .kanban-card-description {
    font-size: 12px;
    word-wrap: break-word;
  }
  
  .kanban-card-label {
    font-size:12px !important;
    width: 120px;
    padding: 1px 0 !important;
  }
  
  .kanban-card-label-border {
    border: 1px solid $kanban-card-label-border !important;
  }
  
  .cursorPointer {
    cursor: pointer;
  }
  
  .kanban-card-action-dropdown .dropdown-toggle {
    background-color: transparent !important;
    color: $kanban-card-action-dropdown-color !important;
    margin: 0 !important;
    border: none !important;
  }
  
  .kanban-card-action-dropdown .dropstart .dropdown-toggle::before {
    display: none !important;
  }
  
  .kanban-card-action-dropdown .btn-primary.dropdown-toggle:focus {
    border: none !important;
    box-shadow: none !important;
  }
  
  .kanban-card-assignees {
    width: 50%;
    overflow-x: auto;
    white-space: nowrap;
  }
  
  .kanban-card-assignee,
  .kanban-card-reporter {
    height: 30px !important;
    width: 30px !important;
    display: inline-block;
  }
  
  .kanban-card-tags {
    font-size: 12px !important;
    align-content: flex-end;
  }
  
  .kanban-card-assignee-name {
    margin-top: 2.5px;
  }
  
  .kanban-card-assignees::-webkit-scrollbar {
    height: 6px;
    transition: 0.3s;
  }
  
  .kanban-card-assignees::-webkit-scrollbar-track {
    background-color: transparent;
    transition: 0.3s;
  }
  
  .kanban-card-assignees::-webkit-scrollbar-thumb {
    background-color: transparent;
    transition: 0.3s;
  }
  
  .kanban-card-assignees:hover::-webkit-scrollbar {
    background-color: $webkit-scrollbar-background-dark;
  }
  
  .kanban-card-assignees:hover::-webkit-scrollbar-track {
    background-color: $webkit-scrollbar-background-light;
  }
  
  .kanban-card-assignees:hover::-webkit-scrollbar-thumb {
    background-color: $webkit-scrollbar-background-dark;
  }
  
  .kanban-card-label-detailed-view {
    width: 250px;
    font-size: 15px;
  }
  
  .kanban-card-preview {
    background-color: $kanban-card-preview-background;
  }

  .dropdown-toggle:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  
  .kanban-board-container {
    overflow-anchor: none; /* disables scroll jumps */
    scroll-behavior: smooth;
  }
  
  .kanban-card-action-dropdown .dropdown-toggle:focus,
  .dropdown-toggle:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  .kanban-dropdown-small .dropdown-menu {
    padding: 0.2rem;
  }
  
  .kanban-dropdown-item {
    display: flex;
    align-items: center;
  }
  
  .kanban-dropdown-item .small-icon {
    font-size: 0.8rem;
  }
  
  .kanban-dropdown-item .small-text {
    font-size: 0.8rem;
  }

  .badge-pill{
    background-color: $webkit-scrollbar-background-dark;
  }
  
  .kanban-lane-border{
    border-right: 1px dotted $webkit-scrollbar-background-light;
  }

  .kanban-card-inner-border-top{
    border-top: 1px solid $border-color-light;
  }

  .kanban-card-inner-border-bottom{
    border-bottom: 1px solid $border-color-light;
  }

}

html.default {
  @import "../../../styles/themes/theme-default.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include Kanban($kanban-heading-color,	$webkit-scrollbar-background-dark,	$webkit-scrollbar-background-light,	$kanban-background,	$kanban-hover-background,	$kanban-card-label-border,	$kanban-card-action-dropdown-color,	$kanban-card-preview-background, $border-color-light, $border-color-dark);
}
html.dark {
  @import "../../../styles/themes/theme-dark.scss";
  @import "../../../styles/theme-color-variables.scss";
  @include Kanban($kanban-heading-color,	$webkit-scrollbar-background-dark,	$webkit-scrollbar-background-light,	$kanban-background,	$kanban-hover-background,	$kanban-card-label-border,	$kanban-card-action-dropdown-color,	$kanban-card-preview-background, $border-color-light, $border-color-dark);
}