.custom-file-upload {
  display: flex;
  .files {
    display: flex;
    align-items: center;
    font-size: 12px;

    .remove-file-icon {
      margin-left: 6px;
      cursor: pointer;
    }

    .image-remove {
      margin-left: 6px;
      cursor: pointer;
      font-size: 10px;
    }

    .custom-width {
      max-width:160px;
      display: flex;
      align-items: center;
    }

    .custom-fileName-width{
      width: 70px;
    }

    .text-nowrap {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 215px;
      display: inline-block;
    }
  }

  .icons {
    font-size: 25px;
  }
}
